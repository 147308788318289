new WOW().init();
$(window).load(function(){
    //ページ内スクロール
    $.smooziee();
    $("html").addClass("html_loaded");
});
$(function(){

    //右クリック禁止
    // $(document).on('contextmenu', function(e) {
    //     return false;
    // });

    var $body = $('body');

    //スクロールしたらクラスを付与
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $body.addClass("scroll");
        } else {
            $body.removeClass("scroll");
        }
    });

    //スクロールしたらクラスを付与（アニメーション用）
    $(window).on('load scroll', function() {
        $(".animate").each(function(){
            var targetAnime = $(this).offset().top;
            var scroll = $(window).scrollTop();
            var windowHeight = $(window).height();
            if (scroll > targetAnime - windowHeight + 200){
                $(this).addClass("moving");
            }
        });
    });

    //開閉用ボタンをクリックでクラスの切替え
    $('.menu_btn').on('click', function () {
        $body.toggleClass('open');
        // $('.header_toggle_btn').removeClass('open');
        // $('.header_toggle_btn').next("ul").removeClass('open');
        return false;
    });

    //下部クローズボタン
    $('.menu_close').on('click', function () {
        $body.removeClass('open');
        // $('.header_toggle_btn').removeClass('open');
        // $('.header_toggle_btn').next("ul").removeClass('open');
        return false;
    });

    //開閉用ボタンをクリックでクラスの切替え
    $('.drop_btn').on('click', function () {
        $(this).toggleClass('open');
        $(this).next("ul").toggleClass('open');
        $(this).next("ul").slideToggle();
    });

    //スマホとタブレットの時だけクリックイベントに変更
    if (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0 || navigator.userAgent.indexOf('iPad') > 0 || navigator.userAgent.indexOf('Android') > 0) {
        // スマートフォン・タブレット向けの記述
        $(".header_bottom .drop_content").hide();
        $(".header_bottom .drop_content").css({'opacity':'1','visibility':'visible'});
        $(".header_bottom .drop_text").hover(function() {
            // カーソルが当たった時の処理
            $(".header_bottom .drop_content").css({'opacity':'1','visibility':'visible'});
        }, function() {
            // カーソルが離れた時の処理
            $(".header_bottom .drop_content").css({'opacity':'1','visibility':'visible'});
        });
        $('.header_bottom .drop_text').on('click', function () {
            if($(this).hasClass("open")){
                $(this).removeClass('open');
                $(this).next("ul").slideUp();
                return false;
            } else {
                $(".header_bottom .drop_text").removeClass('open');
                $(".header_bottom .drop_content").slideUp();
                $(this).addClass('open');
                $(this).next("ul").slideDown();
                return false;
            }
        });

    } else {
        // PC向けの記述
    }

});